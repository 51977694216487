// AuthContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { auth } from '../../services/api/email/firebase';



interface Usertype{
    
    kind: string,
    localId: string,
    email: string,
    displayName: string,
    idToken: string,
    registered: boolean,
    refreshToken: string,
    expiresIn: string

}
interface AuthContextType {
    currentUser: Usertype | null;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);


export const useAuth = () => {
   
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<Usertype | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user: any) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, [auth]);

    const value: AuthContextType = {
        currentUser,
    };

    return (
    <AuthContext.Provider value={value}>
        { !loading && children }
        </AuthContext.Provider>
    )
   
}
