import React, { Suspense, useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import "../assets/scss/loader.scss";

//components
import Root from "./Root";

//home
const Home = React.lazy(() => import("../pages/Home"));

//landings
const Index1 = React.lazy(() => import("../pages/landings/Index1"));
const index2 = React.lazy(() => import("../pages/landings/Index2"));
const index3 = React.lazy(() => import("../pages/landings/Index3"));
const index4 = React.lazy(() => import("../pages/landings/Index4"));
const index5 = React.lazy(() => import("../pages/landings/Index5"));
const index6 = React.lazy(() => import("../pages/landings/Index6"));

// Student application
const studentApplication = React.lazy(()=> import("../pages/internshipApplication"))

// Staff extension
const staffExtension = React.lazy(()=> import("../pages/staffExtension"))

// Website Development
const websiteDevelopment = React.lazy(()=> import("../pages/websiteDevelopment"))

// Blog Post
const writeBlogPost = React.lazy(()=> import("../pages/writeBlogPost"))

//auth
const Login = React.lazy(() => import('../pages/auth/Login'))
// const SignUp = React.lazy(() => import('../pages/auth/Signin'))

// blogs
const Blogs = React.lazy(()=> import("../pages/blogs"))
const BlogPage = React.lazy(()=> import("../pages/blogs/BlogPage")) 

const loading = () => (
  <div className="">
    <div className="center">
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
    </div>
  </div>
);

type LoadingComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadingComponentProps) => {
  return (
    <Suspense fallback={loading()}>
      <Component />
    </Suspense>
  );
};

const AllRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `Brentsoft Technologies - ${
      location.hash
        ? location.hash.substring(1).charAt(0).toUpperCase() +
          location.hash.substring(2)
        : "Home"
    }`;
  }, [location]);
  return useRoutes([
    // {
    //     //root route
    //     path: '/',
    //     element: <Root />
    // },
    {
      //public routes
      path: "/",
      children: [
        {
          path: "",
          element: <LoadComponent component={index6} />,
          // element: <LoadComponent component={Home} />,
        },
        {
          path: "/internship",
          element: <LoadComponent component={studentApplication} />,
        },
        {
          path: "/staff-extension",
          element: <LoadComponent component={staffExtension} />,
        },
        {
          path: "/development",
          element: <LoadComponent component={websiteDevelopment} />,
        },
        {
          path: "/write-blog-post",
          element: <LoadComponent component={writeBlogPost} />,
        },
        {
          path: "blog/:id",
          element: <LoadComponent component={BlogPage} />,
        },
       
        
        {
          path: "/blogs",
          element: <LoadComponent component={Blogs} />,
        },
        // 
        {
          path: "landing",
          children: [
            { path: "index1", element: <LoadComponent component={Index1} /> },
            { path: "index2", element: <LoadComponent component={index2} /> },
            { path: "index3", element: <LoadComponent component={index3} /> },
            { path: "index4", element: <LoadComponent component={index4} /> },
            { path: "index5", element: <LoadComponent component={index5} /> },
            { path: "index6", element: <LoadComponent component={index6} /> },
          ],
        },
        { path: '/login', element: <LoadComponent component={Login} /> },
        // {
        //   path: '/auth',
        //   children: [
        //       { path: '/auth/login', element: <LoadComponent component={Login} /> },
        //       // { path: 'signup', element: <LoadComponent component={SignUp} /> },
        //   ],
        // },
      ],
    },
  ]);
};

export default AllRoutes;
