import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "brentsoft-auth.firebaseapp.com",
    projectId: "brentsoft-auth",
    storageBucket: "brentsoft-auth.appspot.com",
    messagingSenderId: process.env.REACT_APP_MESSENGER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);  incase we need analytics later
// const db = getFirestore(app); incase we need a db later
const auth = getAuth(app); 

export { auth }
